import React, { useEffect, useState } from 'react';
import './App.css';
import { AppConfig } from './appConfig';

function App({ config }: { config: AppConfig }) {
  return (
    <div id='SGI'/>
  );
}

export default App;
